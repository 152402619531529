import { Tinos } from 'next/font/google'

const tinos = Tinos({
  weight: ['400', '700'],
  subsets: ['latin', 'greek'],
  variable: '--font-serif',
  display: 'swap',
})

export default tinos.variable
